import React from 'react';
import { Controller, FieldPath } from 'react-hook-form';

import { DropzoneFieldRequiredProps } from './DropzoneField.types';

import {
  DropzoneFieldControl,
  DropzoneFieldControlRequiredProps
} from './components/DropzoneFieldControl';

interface DropzoneFieldDefaultProps<T> {
  name: FieldPath<T>;
}

type DropzoneFieldProps<T> = DropzoneFieldDefaultProps<T> &
  DropzoneFieldRequiredProps<T> &
  DropzoneFieldControlRequiredProps;

function DropzoneField<T>({
  control,
  disabled,
  name,
  error,
  type,
  labelFor,
  i18nLabel,
  label,
  labelClassName,
  withoutTabs,
  withoutDownload,
  withoutDropzoneArea,
  withoutRemoveButton,
  withoutStatus,
  maxFiles,
  preventMaxFilesOverload,
  initialFiles,
  initialFileIds,
  onRemoveFile
}: DropzoneFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <DropzoneFieldControl
          type={type}
          disabled={disabled}
          error={error}
          labelFor={labelFor}
          i18nLabel={i18nLabel}
          label={label}
          labelClassName={labelClassName}
          withoutTabs={withoutTabs}
          withoutDownload={withoutDownload}
          withoutDropzoneArea={withoutDropzoneArea}
          withoutRemoveButton={withoutRemoveButton}
          withoutStatus={withoutStatus}
          maxFiles={maxFiles}
          preventMaxFilesOverload={preventMaxFilesOverload}
          onChange={onChange}
          initialFiles={initialFiles}
          initialFileIds={initialFileIds}
          onRemoveFile={onRemoveFile}
        />
      )}
    />
  );
}

export default DropzoneField;
